// Here you can add other styles

// .wrapper{
//     background-color: #F8F8F8 !important;
// }

.footer-first{
    background-color: #010101;    
    // border-top: 0px;
    font-size: 16px;
    color: #FFFFFF;
    li{
        list-style: none;
    }
    p{
        color: #FFFFFF;
    }
    a{
        color: #FFFFFF;
        text-decoration: none;
        &:hover{
            color: #EE7676;
        }
    }
    @media (max-width: 767.98px) {
        border-top: none;
    }
    .box-footer{
        
        @media (max-width: 767.98px) {
            width: 178.61px;
        }
    }
    
}
.footer-end{
    background-color: #0A0A0A;
    height: 80px;
    border-top: 0px;
    font-size: 12px;

    a{
        color: #FFFFFF;
        text-decoration: none;
    }
}
//header
.header{
    box-shadow: none !important;
}
.li-match{
    background: #EE7676;
    border-radius: 24px;
    
    .nav-link{
        color: #FFFFFF !important;
    }
}
.li-match-mobile{
    background: #F8F8F8;
    border-radius: 24px;
    .nav-link{
        
        color: #EE7676 !important;
    }
}
//general -revisar
.bold{
    font-weight: bold;
}
.button-group{
    padding: 1px;
    background: #FFFFFF;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
    border-radius: 8.91px;
    button{
        background-color: #FFFFFF;
        color:#333333;
    }
}

.btn-black{
    background: #222222;
}

.title-tips{
    font-weight: 700;
    letter-spacing: 0.35px;
}


.radio-toolbar input[type="radio"] {
    display: none;
}
  
.radio-toolbar label {
    display: inline-block;
    background-color: #FFFFFF;
    // padding: 4px 11px;
    // font-family: Arial;
    font-size: 13px;
    cursor: pointer;
    color:#333333;
    min-width: 108px;
    min-height: 42px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
  
.radio-toolbar input[type="radio"]:checked+label {
    background: #777777;
    border: 0.5px solid rgba(0, 0, 0, 0.04);
    border-radius: 6.93px;
    color: #FFFFFF;
    min-width: 108px;
    min-height: 42px;
}

.c-step-3{
    min-height: 120px;
    font-size: 13px;
}
//eliminar
.card-container{
    margin: 10px;
}



.container-cars {
  
    .hearth{
        width: 50px;
        height: 50px;
        background: #EE7676;
        border-radius: 50px;
        top: 256px;
        position: absolute;
        left: 75.76%;
        display: flex;
        justify-content: center;
        align-items: center;
        color:#FFFFFF;
    }

    .hearth-ranking{
        width: 50px;
        height: 50px;
        background: #C4C4C4;
        border-radius: 50px;
        top: 158px;
        position: absolute;
        left: 75.76%;
        display: flex;
        justify-content: center;
        align-items: center;
        color:#FFFFFF;
    }

    .share{
        width: 42px;
        height: 42px;
        background: #EEEEEE;
        border-radius: 42px;
    }
    .car-card{
        margin: 10px;
        border-radius: 20px;
        background: #F8F8F8;
        box-shadow: none;
        .share-text{
            font-family: 'Ubuntu';
            font-style: normal;            
            font-weight: 500;
            font-size: 14px;
            line-height: 22px;
            color: #EE7676;
        }
        .car-brand{
            font-family: 'Ubuntu';
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 18px;
            color: #333333;
        }
        .title-price{
            font-style: normal;
            font-weight: normal;
            font-size: 10px;
            line-height: 16px;    
            text-align: right;
            letter-spacing: -0.5px;
            color: #AAAAAA;
        }
        .price{
            font-style: normal;
            font-weight: normal;
            font-size: 10px;
            line-height: 16px;   
            color: #000000; 
            
        }
        .car-model{
            font-weight: bold;
            font-size: 22px;
            line-height: 25px;
            letter-spacing: -0.5px;
            color: #000000;
        }
        .car-img{
            border-radius: 20px;
            // height: 185px;
            // @media (max-width: 767.98px) { 
            //     height: 214px;
            // }
        }
        .car-specs{
            font-family: 'Ubuntu';
            font-style: normal;
            font-weight: normal;
            font-size: 13px;
            line-height: 16px;           
            color: #000000;
        }
    }
        
    
}
.container-title, .container-title-home{
    display: flex;
    justify-content: center;
    // align-items: center;
    background: linear-gradient(220.67deg, #FFF8F8 2.79%, #FFE9E9 84.86%);
    min-height: 213px;
    flex-direction: column;
}
//step-1
.epigraph{
    color: #EE7676;
    font-size: 12px;
    line-height: 0px;
    text-transform: uppercase;
    font-weight: 500;
}
.container-step-one{
    background-color: #FFFFFF;
    padding-top: 40px;
    border-radius: 40px 40px 0px 0px;
    top: -32px;
    position: relative;
    height: 100%;
    .card-slider{
        background: #F8F8F8;
        border-radius: 5px;
        box-shadow: none;
        .slider-options{
            color:#000000;
        }
        .hr-bk{
            position: absolute;
            height: 100%;
            width: 0.2vw;
            color: #000;
            background-color: #000;
            left: 49.7%;
            margin-top: 0px;
            border: 0.12vw dotted white;
        }
    }
}
//step-3
#step-3, .login, .edit-profile{
    label{
        color: #555555;
        font-size: 16px;
        font-weight: 500;
    }
    input[type=text], input[type=password]{
        background: #F8F8F8;
        border: 1px solid #DDDDDD;
        box-sizing: border-box;
        border-radius: 4px;
        height: 52px;
    }
    .check-conditions{
        color:#AAAAAA;
    }
    .form-select {
        border: 1px solid #DDDDDD;
        box-sizing: border-box;
        border-radius: 4px;
        height: 52px;
        background-color: #F8F8F8;
    }
}

h1{
    color:#222222;
    // font-size: 20px;
}
p{
    color:#555555;
}
//resultados
.car-selected{
    box-shadow: 0px 0px 4px 1px #EE7676 !important;
}
.sidebar-profile{
    .sidebar-profile-header{
        background-color: #80BE60;
        border: 0;
        border-radius: 40px 40px 0px 0px;
        p{
            color: #FFFFFF;
        }
        p:first-child{
            font-weight: 400;
            font-size: 10px;
            line-height: 11px;
        }
    }
    .sidebar-profile-description{
        background-color:#EEEEEE;
        .title{
            font-weight: 400;
            font-size: 10px;
            line-height: 11px;
            letter-spacing: 1px;
            color: #555555;
        }
        .text{
            font-weight: 400;
            font-size: 14px;
            line-height: 18px;
            letter-spacing: -0.5px;
            color: #222222;
        }
    }
    .sidebar-profile-footer{
        border: 0;
        border-radius: 0px 0px 40px 40px ;
        background-color: #555555;
        color:#FFFFFF;
    }
}
.car-section-results{
    .container-cars{
        .car-card{
            margin: 10px;
            border-radius: 20px;
            background: #F8F8F8;
            box-shadow: none;
            .share-text{
                font-family: 'Ubuntu';
                font-style: normal;            
                font-weight: 500;
                font-size: 14px;
                line-height: 22px;
                color: #EE7676;
            }
            .car-brand{
                font-family: 'Ubuntu';
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 18px;
                color: #333333;
            }
            .title-price{
                font-style: normal;
                font-weight: normal;
                font-size: 10px;
                line-height: 16px;    
                text-align: right;
                letter-spacing: -0.5px;
                color: #AAAAAA;
            }
            .price{
                font-style: normal;
                font-weight: normal;
                font-size: 10px;
                line-height: 16px;   
                color: #000000; 
                
            }
            .car-model{
                font-weight: bold;
                font-size: 22px;
                line-height: 25px;
                letter-spacing: -0.5px;
                color: #000000;
            }
            .car-img{
                border-radius: 20px;
                height: 185px;
                // @media (max-width: 767.98px) { 
                //     height: 214px;
                // }
            }
            .car-specs{
                font-family: 'Ubuntu';
                font-style: normal;
                font-weight: normal;
                font-size: 13px;
                line-height: 16px;           
                color: #000000;
            }
        }
    }
   
}
.filter-cars{
    background-color: #F8F8F8;
    // box-shadow: 0px 0px 4px 1px #EE7676;
    border-radius: 20px;
    @media (min-width: 992px) { 
        width: 792px;
    }
}
.car-comparison{
    // margin-top: 40px;
}
.box {
    // width: 150px;
    // height: 75px;
    background: linear-gradient(220.67deg, #FFF8F8 2.79%, #FFE9E9 84.86%);
    // color: #fff;
    padding: 20px;
    position: relative;
    // margin: 40px;
    // float: left;
    p{
        color: #666666;
        text-align: center;
        letter-spacing: -0.5px;
        font-weight: 400;
        font-size: 18px;
        line-height: 21px;
    }
}
  
 
  .box.arrow-top:after {
    content: " ";
    position: absolute;
    right: 40%;
    top: -30px;
    border-top: none;
    border-right: 30px solid transparent;
    border-left: 30px solid transparent;
    border-bottom: 30px solid #FFF5F4;
  }
.car-comparison::after {
    content:'';
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -50px;
    width: 0;
    height: 0;
    border-top: solid 50px #e15915;
    border-left: solid 50px transparent;
    border-right: solid 50px transparent;
}
.car-results{
    background: #F8F8F8;
    // border:1px solid red;
    border-radius: 20px;
    box-shadow: none;
    @media (min-width: 992px) { 
        width: 792px;
        .rowCars .first-col{
            width: 282px;
            height: 192px
        }
        .rowCars .second-col{
            width: 510px;
        }
    }
    .title-container{
        border-bottom: 1px solid #EEEEEE;
        color: #000000;
        border-radius: 0px 20px 0px 0px;
    }
    .stars-container{
        font-size: 13px;
        color: #000000;
        font-weight: 400;
    }
    .prices-container{
        font-size: 12px;
        .text-score{
            color: #AAAAAA;
            font-weight: 400;
            .score{
                background: #80BE60;
                border-radius: 5px;
                font-weight: 500;
                font-size: 14px;
                color:#FFFFFF;
            }
        }       
        .prices{            
            .price-container{
                background: #FFFFFF;
                border-radius: 5px;
            }
        }
    }
    .card-footer{
        .form-check{
            label{
                font-size: 12px;
            }
        }
    }
}
.profile-container{
    height: 100px;
    background: #EE7676;
    border-radius: 0px 0px 40px 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #FFFFFF;
  
}
.profile{
    background: #222222;
    border-radius: 24px;
    width: 119px;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.hero{
    padding-bottom: 122px;
    background: linear-gradient(220.67deg, #FFF8F8 2.79%, #FFE9E9 84.86%);
    .fig-container{
        display: flex;
        align-items: center;
        .fig-1{
            height:calc(100vw / 9);
            width: 316px;
            border-radius: 0px 200px 200px 0px;
            background-size: cover;
        }
        .fig-2{
            height:calc(100vw / 9);
            width: 608px;
            background: #80BE60;
            border-radius: 200px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .fig-3, .fig-4{
           width: 180px;
            // height: 180px;
            background: #9BD1DD;
            // border-radius: 200px;
            // width:15%;
            height:calc(100vw / 9);
            border-radius:50%;
        }
        .fig-5{
            height:calc(100vw / 9);
            width: 235px;
            background: #EE7676;
            border-radius: 200px 0px 0px 200px;
        }
    }
}
.profile-data{
    background: #EE7676;
    border-radius: 40px;
    height: 792px;
    top: -56px;
    position: relative;
    .container-data{
        padding-right: 6rem !important;
        padding-left: 6rem !important;
        .profile-info{
            display: flex;
            justify-content: center;
            align-items: center;
            background: rgba(34, 34, 34, 0.4);
            height: 70px;
            color: #FFFFFF;
            border-radius: 10px;
        }
        .profile-info-box{
            background: rgba(34, 34, 34, 0.4);
            border-radius: 10px;
            .text-profile-italic{
                text-align: center;
                letter-spacing: -0.5px;
                color: #FFFFFF;
                font-style: italic;
                font-weight: normal;
                font-size: 16px;
                line-height: 24px;
            }
        }

    }
  
}
.car-section{
    border-radius: 40px;
}
//home
.nav-home{
    width: 90%;
    background: #FFFFFF;
    box-shadow: 0px 4px 24px rgba(34, 34, 34, 0.08) !important;
    border-radius: 200px;
    align-self: center;
    position:absolute;
    border-bottom: none;
    .nav-link{
        color: #222222;
        font-weight: 500;
        font-size: 15px;
    }
    @media (max-width: 767.98px) { 
        position: sticky;
        top: 0;
        z-index: 1029;
        width: 100%;
        border-radius: 0px;
    }
}

.nav-home-dark{
    width: 100%;
    background: #EE7676;
    box-shadow: 0px 4px 24px rgba(34, 34, 34, 0.08) !important;
    align-self: center;
    position:absolute;
    border-bottom: none;
    .nav-link{
        color: #ffffff;
        font-weight: 500;
        font-size: 15px;
    }

    .li-match{
        background: #000000;
        border-radius: 24px;
        
        .nav-link{
            color: #FFFFFF !important;
        }
    }
    .li-match-mobile{
        background: #EE7676;
        border-radius: 24px;
        .nav-link{
            
            color: #000000 !important;
        }
    }
    
    @media (max-width: 767.98px) { 
        position: sticky;
        top: 0;
        z-index: 1029;
        width: 100%;
        border-radius: 0px;
    }
}
.container-title-c{
    padding-top: 158px;
    padding-bottom: 78px;
    @media (max-width: 767.98px) { 
        padding-top: 60px;
        padding-bottom: 60px;
    }
}

.container-title-home{
    padding-top: 35px;
    padding-bottom: 78px;
    h1{
        letter-spacing: -0.5px;
        color: #222222;
        font-family: 'Ubuntu';
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
    }
    @media (min-width: 768px) { 
        padding-top: 140px;
        h1{
            font-size: 36px;
        }
    }
    .figures{
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: center;
        .fig-one{
            width: 602px;
            height: 355px;
            background: #80BE60;
            border-radius: 500px 0px 0px 500px;
            transform: matrix(-1, 0, 0, 1, 0, 0);
            .container{
                transform: matrix(-1, 0, 0, 1, 0, 0);
                width: 80%;

                .title-fig{
                    font-weight: 500;
                    letter-spacing: -0.5px;
                    font-size: 24px;
                    color: #FFFFFF;
                }
                p{
                    font-weight: 400;
                    font-size: 16px;
                    letter-spacing: -0.5px;
                }
                a{
                    text-decoration: none;
                    .btn{
                        background: #FFFFFF;
                        color: #80BE60 ;
                        font-weight: 500;
                        display: flex;  
                        align-items: center;
                    }
                }
               
            }
       
        }
        .fig-two{
            background: #EE7676;
            border-radius: 500px;
            width: 355px;
            height: 355px;
            display: flex;
            justify-content: center;
            align-items: center;
         
        }

        @media (max-width: 374.98px) { 
            flex-direction: column;
            align-items: center;
            .fig-one{
                width: 378px;
                height: 264.22px;
                transform: matrix(0, -1, -1, 0, 0, 0);
                margin-bottom: 65px;
                .container{
                    transform: matrix(0, -1, -1, 0, 0, 0);
                    width: 60%;
                    text-align: center;
                    padding-top: 0.5rem !important;
                    padding-bottom: 0.5rem !important;
                    
                }
            }

            .fig-two{
                width: 265px;
                height: 265px;
                margin-bottom: 10px;
            }

            .fig-three{
                img{
                    width: 100%;
                }
            }
        }
        @media (min-width: 375px) and (max-width: 767.98px) { 
            flex-direction: column;
            align-items: center;
            .fig-one{
                transform: matrix(0, -1, -1, 0, 0, 0);
                width: 450px;
                height: 350.22px;
                margin-bottom: 60px;
                display: flex;
                justify-content: center;
                .container{
                    transform: matrix(0, -1, -1, 0, 0, 0);
                    width: 60%;
                    text-align: center;
                    padding-top: 3rem !important;
                    padding-bottom: 3rem !important;
                }
            }

            .fig-two{
                margin-bottom: 15px;
            }
          
        }
        @media (min-width: 768px) and (max-width: 991.98px) { 
            .fig-one{
                width: 545px;
                height: 285px;
                background: #80BE60;
                border-radius: 500px 0px 0px 500px;
                transform: matrix(-1, 0, 0, 1, 0, 0);
                .container{
                    transform: matrix(-1, 0, 0, 1, 0, 0);
                    width: 80%;   
                    .title-fig{
                        font-size: calc(1.3rem + 0.6vw);
                    }           
                }
           
            }
            .fig-two{
                width: 228px;
                height: 242px;
            }

            .fig-three{
                img{
                    max-width: 100%; 
                    height: auto;
                }
            }
            
        }
        @media (min-width: 992px) and (max-width: 1199.98px) { 
            .fig-one{            
                .container{                   
                    .title-fig{
                        font-size: 32px;
                    }           
                }
           
            }
            .fig-two{
                width: 300px;
                height: 300px;
            }

            .fig-three{
                img{
                    width: 300px;
                    height: 300px;
                }
            }
            
        }

        @media (min-width: 1200px) {
            .fig-one{             
                .container{
                    .title-fig{                      
                        font-size: 40px; 
                        width: 415px;                       
                    }
                  
                }
           
            }
        }
    }
}
.ideal-cars-container{
    border-radius: 40px 40px 0px 0px;
    background: #FFFFFF;
    position: relative;
    top: -43px;
    padding-top: 20px;

    .title-stars{
        font-weight: 500;
        font-size: 18px;
        color: #222222;
        letter-spacing: 0.35px;
    }
    .articles-container{
        margin: 10px;
        height:260px;
        background-size: cover;
        background-position: center;
        border-radius: 20px;
        color: #FFFFFF;
      
        .circle-green, .circle-blue, .circle-yellow, .circle-red {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 16px;
            width: 55px;
            height: 56px;            
            border-radius: 50px;
        }
        .circle-green{
            background: #80BE60;
        }
        .circle-blue{
            background: #9BD1DD;
        }
        .circle-yellow{
            background: #F5B301;
        }
        .circle-red{
            background: #EE7676;
        }
         
        p{
            color: #FFFFFF;
            font-weight: 500;
            font-size: 32px;
            line-height: 37px;
            letter-spacing: -0.5px;
        }
    }
}
.ranking{
    height: 202px;
    background: #80BE60;
    border-radius: 0px 0px 40px 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media (max-width: 767.98px) { 
        justify-content: center;    
        padding-left: 1em;
        padding-right: 1em;  
    }     
    .figures-container-one{
        display: flex;
        .figure-one{
            min-height: 42px;
            width: 152px;
            background: #9BD1DD;
            border-radius: 0px 200px 200px 0px;
        }
        .figure-circle{
            width: 42px;
            height: 42px;
            background: #EE7676;
            border-radius: 200px;
        }
        @media (max-width: 767.98px) { 
            display: none;          
        }
    }
    .content{
        display: flex;
        align-items: center;  
        @media (max-width: 767.98px) { 
            flex-direction: column;          
        }     
        .text-content{    
            width: 57%;
            text-align: end;
            font-size: 20px;
            letter-spacing: -0.5px;
            @media (max-width: 767.98px) { 
                font-size: 13px;
                text-align: center;    
                margin-bottom: 20px;   
                width: 80%;
            }   
        }
    }
    .figures-container-two{
        display: flex;
        .figure-one{
            height: 42px;
            width: 152px;
            background: #EE7676;
            border-radius: 200px 0px 0px 200px;
        }
      
        .figure-circle{
            width: 42px;
            height: 42px;
            border-radius: 200px;
            margin-right: 1px;
            &:nth-child(1){ 
                background: #9BD1DD;
               
                
            } 
            &:nth-child(2){ 
                background: #F5B301;                
            }
        }       
        @media (max-width: 767.98px) { 
            display: none;          
        }
    }
}
.banner-container{
    // border-radius: 40px 40px 0px 0px;
    // background: #FFFFFF;
    // position: relative;
    // top: -43px;
    // padding-top: 20px;
    .banner{
        height: 206px;
        max-width: 996px;
        // background: #C4C4C4;
        // border-radius: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        // text-transform: uppercase;
        img{
            border-radius: 20px;
        }
    }
}
.tips{
    background:#9BD1DD;
    
    border-radius: 40px 40px 0px 0px;
    padding-bottom: 75px;
    
}
.container-tips{
    margin-right: 0px;
    padding-right: 0px;
    padding-left: 8%;
    
}
.articles-container{
    margin: 10px;
    height:260px;
    background-size: cover;
    background-position: center;
    border-radius: 20px;
    color: #FFFFFF;
    .title-articles{
        color: #EEEEEE;
        font-weight: 400;
        font-size: 12px;
    }
    .extract-articles{
        font-size: 18px;
        font-weight: 500;
        letter-spacing: -0.5px;
    }
    p{
        color: #FFFFFF;
    }
}


//steper

.steps{
    color: #888888;
    font-size: 13px;
    font-weight: 500;
    .step{
        color: #EE7676;
    }
}
.step-login{
    color: #888888;
    font-size: 14px;
    a{
        text-decoration: none;
        color: #EE7676;
    }
}

$theme-colors: (
  "primary": #FF7674,
  "dark" : #222222,
  "success" : #80BE60,
  // "danger": #ff4136
);
.text-dark{
    color: #222222 !important;
}
.btn-green, .bg-green{
  background-color: #80BE60;
}

.text-green{
   color: #80BE60; 
}

.btn-dark{
    background-color: #222222;
}

.btn-blue, .bg-blue{
    background-color: #9BD1DD;
}

.text-blue{
    color:#9BD1DD;
}

.bg-yellow{
    background-color: #F5B301;
}

.text-yellow{
    color:#F5B301;
}
//profile

.card-info{
    background: #FFFFFF;
    border-radius: 40px;
    min-height: 800px;
    box-shadow: none;
    .title-card-info{
        font-size: 20px;
        font-weight: 400;
    }
}

.info-profile-driver{
    background: #F8F8F8;
    border-radius: 0px 0px 40px 40px;
    .title-profile{
        background-color: #80BE60;
        border: 0;
        border-radius: 40px 40px 0px 0px;
        color: #FFFFFF;
    }
}

//Ranking
.container-title-ranking{
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(220.67deg, #FFFBF2 2.79%, #FFE9AE 84.86%);
    color: #EE7676;
    min-height: 213px;
    flex-direction: column;
}

.container-layout-cars{
    background: #EE7676;
    border-radius: 0px 0px 40px 40px;
    .container-cars-ranking{
        background-color: #FFFFFF;
        padding-top: 15px;
        border-radius: 40px 40px 40px 40px;
        top: -32px;
        position: relative;
        height: 100%;
        .card-slider{
            background: #F8F8F8;
            border-radius: 5px;            
            .slider-options{
                color:#000000;
            }
        }
    }
}

.interest{
    .container-interest{
        .articles-container{
            margin: 10px;
            height: 260px;
            background-size: cover;
            background-position: center;
            border-radius: 20px;
            color: #FFFFFF;
            p{
                color: #FFFFFF;
            }
        }
    }
}

// container title custom artiulos -- global
.container-round{
    background-color: #FFFFFF;
    padding-top: 15px;
    border-radius: 40px 40px 0px 0px;
    // top: -32px;
    // position: relative;
    height: 100%;
    .card-slider{
        background: #F8F8F8;
        border-radius: 5px;
        .slider-options{
            color:#000000;
        }
    }
}
.container-round-c{
    background-color: #FFFFFF;
    padding-top: 15px;
    border-radius: 40px;
    // top: -32px;
    // position: relative;
    height: 100%;
    .card-slider{
        background: #F8F8F8;
        border-radius: 5px;
        .slider-options{
            color:#000000;
        }
    }
}

.container-title-global{
    padding-top: 140px;
    @media (max-width: 767.98px) { 
        padding-top: 46px;
    }
    // padding-bottom: 78px;
}
.container-title-custom{
    display: flex;
    justify-content: center;
    align-items: center;
    // background: linear-gradient(220.67deg, #FFFBF2 2.79%, #FFE9AE 84.86%);
    // color: #EE7676;
    min-height: 213px;
    flex-direction: column;
}

.bg-pink{
    background: linear-gradient(220.67deg, #FFF8F8 2.79%, #FFE9E9 84.86%);
}
.text-pink{
    color: #EE7676;
}

.text-black{
    color: #000000;
}

.s-bold{
    font-weight: 500;
}

.container-icon{
    width: 50px;
    height: 50px;
    // background: #EE7676;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    color:#FFFFFF;
}

.bg-pink-c{
    background-color: #EE7676;
}

.pagination{
    .page-link{
        border-radius: 50px;
        border: 1px solid #EE7676;
        margin: 15px;
        padding: 5px;
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }
}
html:not([dir=rtl]) .page-item:first-child .page-link {
    border-bottom-left-radius: 50px !important;
    border: 1px solid #999999;
    color: #999999;
    border-top-left-radius: 50px !important;
}


html:not([dir=rtl]) .page-item:last-child .page-link {
    border-bottom-right-radius: 50px;
    border-top-right-radius: 50px;
    border: 1px solid #999999;
    color: #999999;  
}

.container-title-figures{
    margin-top: 70px;
    .figures-container-one{
        display: flex;
       
        .figure-one{
            min-height: 42px;
            width: 162px;
            background: #80BE60;
            border-radius: 0px 200px 200px 0px;
        }
        .figure-circle{
            width: 42px;
            height: 42px;
            // background: #EE7676;
            border-radius: 200px;
            margin-left: 10px;
          
        }

        .figure-circle:nth-child(2){
            background-color: #EE7676;
        }
        .figure-circle:nth-child(3){
            background-color: #F5B301;
        }

        .figure-circle:nth-child(4){
            background-color: #9BD1DD;
        }
        @media (max-width: 767.98px) { 
            display: none;          
        }
    } 
}

//Article

.rrss-btn{
    width: 50px;
    height: 50px;
    background: #EEEEEE;
    border-radius: 50px;
    top: 256px;
    // position: absolute;
    // left: 75.76%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #777777;

}
.img-article{
    border-radius: 40px;
}
.text-silver{
    color: #AAAAAA;
}

.container-layout-article{
    border-radius: 40px 40px 0px 0px;
    background-color: #9BD1DD;
}

//about Us
.card-about-us{
    background: #F8F8F8;
    border-radius: 20px;
    box-shadow: none;
}

.card-payment{
    background: #FFFFFF;
    border-radius: 40px;
    box-shadow: none;
    font-weight: 500;
}

//radio - pago

#radios-plan{
    label {
        cursor: pointer;
        position: relative;
    
    }
      
    #radios label + label {
    // margin-left: 15px;
    // margin-bottom: 20px;
    }
      
    input[type="radio"] {
    opacity: 0; 
    position: absolute;
    top: 38%;
    left: 50px;
    @media (max-width: 374.98px) {
        left: 9px;
    }
    @media (min-width: 375px) and (max-width: 575.98px)  {
        left:20px;
    }
    }
      
    input[type="radio"] + div {
        background: #FFFFFF;
        border: 1px solid #80BE60;
        border-radius: 16px;
        width: 241px;
        min-height: 424px;
        // font-family: 'Material Icons';
    
        // border-radius: 20px;
        // // padding: 8px;
        // width: 140px;
        // height: 28px;
        // transition: all 0.4s;
        // -webkit-transition: all 0.4s;
        // padding-top: 20px;
        // padding-bottom: 30px;
        // padding-left: 84px;
        // padding-right: 84px;
        // background: #FFFFFF;
        // border: 1px solid #DDDDDD;
        // @media (max-width: 374.98px) {
        //     padding-left: 10px;
        //     padding-right: 30px;
        // }
        // @media (min-width: 375px) and (max-width: 575.98px) {
        //     padding-left: 30px;
        // }
        p{
            color: #222222;
            font-weight: 400;
            font-size: 14px;
            .check{
                color: #80BE60;
            }
        }    
        .plan{
            font-weight: 400;
            font-size: 10px;
            color: #80BE60;
        }
        .plan-name{
            font-weight: 700;
            font-size: 20px;
            color: #80BE60;
        }
        .price{
            color:#80BE60;
            font-weight: 700;
            font-size: 34px;
        }
    }
      
    input[type="radio"] + div .txt {
        // font-family: 'Material Icons';
        color: black;
        display: none;
    
    }
    input[type="radio"] + div  .ics {
        // font-family: 'Material Icons';
        color: #CE357D;
    
    }
      
    input[type="radio"]:checked + div {
        // color: #CE357D;
        background-color: #80BE60;
        // border: 1px solid #CE357D;
        .check{
            color: #FFFFFF;
        }
        .plan{
            font-weight: 400;
            font-size: 10px;
            color: #FFFFFF;
        }
        .plan-name{
            font-weight: 700;
            font-size: 20px;
            color: #FFFFFF;
        }
        .price{
            color:#FFFFFF;
            font-weight: 700;
            font-size: 34px;
        }
    }
      
    input[type="radio"]:focus + div {
        // color: #CE357D;
    }
    
}


#radios{
    label {
       cursor: pointer;
       position: relative;
   }
     
   #radios label + label {
   // margin-left: 15px;
   // margin-bottom: 20px;
   }
     
   input[type="radio"] {
   // opacity: 0; 
   position: absolute;
   top: 38%;
   left: 50px;
   @media (max-width: 374.98px) {
       left: 9px;
   }
   @media (min-width: 375px) and (max-width: 575.98px)  {
       left:20px;
   }
   }
     
   input[type="radio"] + span {
       // font-family: 'Material Icons';
   
       border-radius: 20px;
       // padding: 8px;
       width: 140px;
       height: 28px;
       transition: all 0.4s;
       -webkit-transition: all 0.4s;
       padding-top: 20px;
       padding-bottom: 30px;
       padding-left: 84px;
       padding-right: 84px;
       background: #FFFFFF;
       border: 1px solid #DDDDDD;
       @media (max-width: 374.98px) {
           padding-left: 10px;
           padding-right: 30px;
       }
       @media (min-width: 375px) and (max-width: 575.98px) {
           padding-left: 30px;
       }
   
   }
     
   input[type="radio"] + span .txt {
       // font-family: 'Material Icons';
       color: black;
       display: none;
   
   }
   input[type="radio"] + span  .ics {
       // font-family: 'Material Icons';
       color: #CE357D;
   
   }
     
   input[type="radio"]:checked + span {
       // color: #CE357D;
       background-color: #F8F8F8;
       // border: 1px solid #CE357D;
   }
     
   input[type="radio"]:focus + span {
       // color: #CE357D;
   }
   
}
//login

.card-login{
    background: #FFFFFF;
    border-radius: 40px;
    box-shadow: none;
}


.rc-slider-dot, .rc-slider-mark-text {
    display: none !important;
}
//modal

.modal-content{
    background: rgb(248, 248, 248) !important;
    .modal-header{
        border-bottom: none;
    }
}
// resultados-tabla

.table-results{
    border-collapse: collapse;
    th img{
        border-radius: 20px 20px 0px 0px;
    }
    tbody{
        tr{
            border-bottom: 1px solid rgba(0, 0, 0, 0.05);
        }
        tr:last-child{
            border-bottom: none;
        }
        tr:not(:first-child) td{
            padding-top: 6px;
            padding-bottom: 6px;
        }
        tr:first-child td:first-child{
            border-radius: 20px 0px 0px 0px;
            padding-top: 40px;
            padding-bottom: 40px;
        }
        tr:first-child td:not(:first-child){
            font-size: 18px;
            font-weight: 700;
            color: #000000;
        }
        tr:last-child td:first-child{
            border-radius: 0px 0px 0px 20px
        }
        tr:last-child td:last-child{
            border-radius: 0px 0px 20px 0px
        }
        tr td:first-child{
            background-color:#EE7676 ;
            color: #FFFFFF;
            padding-left: 40px;
            padding-right: 40px;
        }
        tr td:not(:first-child){
            
            background-color: #FFFFFF;
          
        }
        .text-features{
            font-weight: 500;
            font-size: 14px;
        }
        .text-score{
            color: #AAAAAA !important;
            font-weight: 400 !important;
            color: #222222;
            .score{
                background: #80BE60;
                border-radius: 5px;
                font-weight: 500;
                font-size: 14px;
                color:#FFFFFF;
            }
        }
    }
    
}

.table-results-mobile{
    th, td {
        // padding: 12px;
    }
    thead{
        background-color: #EE7676;
        color:#FFFFFFFF;
        th:first-child{
            border-radius: 20px 0px 0px 20px;
        }    
        th:last-child{
            border-radius: 0px 20px 20px 0px;
            border-right: none;
        } 
        th{
            padding-left: 15px;
            padding-right: 15px;
            text-align: center;
            border-right: 2px solid rgba(0, 0, 0, 0.05);
            font-weight: 500;
            font-size: 14px;
            text-transform: uppercase;
        }         
    }
    tbody{
        tr{
            td:first-child{
                border-radius: 20px 0px 0px 20px;
                background-color: #FFFF;
                img{
                    border-radius: 20px 0px 0px 0px;
                }
                font-weight: 700;
                font-size: 18px;
                color: #000000;
            }
            td{
                border-right: 2px solid rgba(0, 0, 0, 0.05);
            }
            td:not(:first-child), td:not(:nth-child(2) ){
                font-weight: 500;
                font-size: 14px;
                color: #222222;              
            }
            td:last-child{
                border-right: none;
            }
        }
    }
    .text-score{
        color: #AAAAAA !important;
        font-weight: 400 !important;
        .score{
            background: #80BE60;
            border-radius: 5px;
            font-weight: 500;
            font-size: 14px;
            color:#FFFFFF;
        }
    }  
}

.btn-print{
    background: #FFFFFF;
    border-radius: 24px;
    box-shadow: 'unset' !important;
}


//spinner

.lds-ellipsis {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-ellipsis div {
    position: absolute;
    top: 33px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: #fff;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  .lds-ellipsis div:nth-child(1) {
    left: 8px;
    animation: lds-ellipsis1 0.6s infinite;
    background: #80BE60 !important;
  }
  .lds-ellipsis div:nth-child(2) {
    left: 8px;
    animation: lds-ellipsis2 0.6s infinite;
    background: #9BD1DD !important;
  }
  .lds-ellipsis div:nth-child(3) {
    left: 32px;
    animation: lds-ellipsis2 0.6s infinite;
    background: #F5B301 !important;
  }
  .lds-ellipsis div:nth-child(4) {
    left: 56px;
    animation: lds-ellipsis3 0.6s infinite;
    background: #EE7676 !important;
  }
  @keyframes lds-ellipsis1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes lds-ellipsis3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
  @keyframes lds-ellipsis2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(24px, 0);
    }
  }
  